
// returms Navbar links.
// Should be replaced by a more Dynamic approach, however load time is something to consider.

import { navLink, navSection } from "./types"

export const NAVLINKS: navLink[] = [
    {
        title: "ACCOMMODATION",
        path: "/accommodation"
    },
    {
        title: "DINING",
        path: "/dining"
    },
    {
        title: "EVENTS",
        path: "/events"
    },
    {
        title: "EXPERIENCES",
        path: "/experiences"
    },
    {
        title: "ABOUT",
        path: "/about"
    },
];

export const NAVSECTIONS: navSection[] = [
    {
        title: "Accommodation",
        path: "/accommodation",
        navLinks: [
            {
                title: "Cabins",
                path: "/accommodation#cabins"
            },
            {
                title: "Facilities and Services",
                path: "/accommodation#facilities"
            },
            {
                title: "Exclusive Packages",
                path: "/packages"
            }
        ]
    },
    {
        title: "Dining",
        path: "/dining",
        navLinks: [
            {
                title: "Warrior Restaurant and Bar",
                path: "/dining#warrior"
            },
            {
                title: "Menus",
                path: "/menu"
            },
            {
                title: "Reservations",
                path: "/dining#reservation"
            }
        ]
    },
    {
        title: "Events",
        path: "/events",
        navLinks: [
            {
                title: "Events at Warrior",
                path: "/events/warrior"
            },            {
                title: "Weddings",
                path: "/weddings"
            },            {
                title: "Private Functions",
                path: "/functions"
            }
        ]
    },
    {
        title: "Experiences",
        path: "/experiences",
        navLinks: [
            {
                title: "Australia Zoo",
                path: "/experiences#australiazoo"
            },
            {
                title: "Australia Zoo Wildlife Hospital",
                path: "/experiences#hospital"
            },
            {
                title: "Sunshine Coast",
                path: "/experiences/sunshinecoast"
            }
        ]
    },
    {
        title: "About",
        path: "/about",
        navLinks: [
            {
                title: "Our Story",
                path: "/about/story"
            },
            {
                title: "Contact Us",
                path: "/about#contact"
            },
            {
                title: "Map and Directions",
                path: "/about/directions"
            },
            {
                title: "Accessibility",
                path: "/about/accessibility"
            },
            {
                title: "Gallery",
                path: "/about/gallery"
            },
            {
                title: "Awards",
                path: "/about/awards"
            },
            {
                title: "FAQ",
                path: "/about/faq"
            }
        ]
    }
];